import {
  GET_SUMMERCAMP_EVENTS,
  REGISTER_SUMMERCAMP_EVENTS,

  } from "./actionType";
  import HttpService from "../../services/HttpService"


  export const getSummerCampEvents = (data) => ({
    type: GET_SUMMERCAMP_EVENTS,
    payload: {
      request: {
        method: HttpService.HttpMethods.POST,
        url: '/summer-camp/list',
        data: data
      },
    }
  })

  export const registerSummerCampEvent = (data) => ({
    type: REGISTER_SUMMERCAMP_EVENTS,
    payload: {
      request: {
        method: HttpService.HttpMethods.POST,
        url: '/summer-camp/registration',
        data: data
      },
    }
  })
