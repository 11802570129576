import React, { useState } from "react"
//import {Link} from "react-router-dom";
import { useDispatch } from "react-redux"
import { t } from "i18next"
// import './custom.css'
// import { useTranslation } from 'react-i18next'
// import { send } from 'emailjs-com'
import ReCAPTCHA from "react-google-recaptcha"
import { NotificationManager } from 'react-notifications'
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Link } from "react-router-dom"
import {
  registerSummerCampEvent,
  // getSummerCampEvents,
} from "../modules/actions"



const PrihlaskaLetnyTabor = () => {
  const dispatch = useDispatch()

  // const { summerCampsEvents } = useSelector((state) => (
  //   {
  //     summerCampsEvents: state.SummerCampReducer.summerCampsEvents,
  //     // isContactUpdate: state.Students.isContactUpdate,
  //   }
  // ))


  const today = new Date()
  const defaultPrice = 179
  const [price, setPrice] = useState(defaultPrice)
  // const [selectedEvents, setSelectedEvent] = useState([])
  
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked)

    const discount = 20
    const newPrice = !isChecked ? price - discount : price + discount
    setPrice(Math.round(newPrice))
  }


  // if (new Date('2024-06-01') > today) {
  //   setPrice(Math.round(defaultPrice * 0.9))
  // }    

  React.useEffect(() => {
  //   // dispatch(getSummerCampEvents())
  if (new Date('2025-05-01') > today) {
    // setPrice(Math.round(defaultPrice * 0.9))    
    setPrice(Math.round(169))    
  } else {
    setPrice(defaultPrice)
  }   

  // if(events.length > 0) {
  //   const x = price * events.length
  //   setPrice(Math.round(x))    
  // }

  }, [])




  const captchaRef = React.useRef()

  const defaultValues = {
    price: price,
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    age: '',
    height: '',
    message: '',
    events: []
  }

  const onSubmit = (values, resetForm) => {
    const token = captchaRef.current.getValue()
    values.price = price
    values.has_own_gi = isChecked

    // send(
    //     'service_roxp2n8',
    //     'template_d6rrhyt',
    //     {
    //         ...values,
    //         'g-recaptcha-response': token,
    //     },
    //     'i0xGfmqONO5OrVi-a'
    // )
    if (token) {
      const data = {
        ...values,
        recaptcharesponse: token
      }
      dispatch(registerSummerCampEvent(data))
        .then((response) => {
          // console.log('SUCCESS!', response.status, response.text)
          NotificationManager.success('Success message', 'Email bol odoslaný.', 3000);
          setIsChecked(false)
  
          if (new Date('2025-05-01') > today) {
            setPrice(Math.round(defaultPrice * 0.9))
          } else {
            setPrice(defaultPrice)
          }    
      
          
        })
        .catch((err) => {
          console.log('FAILED!', err)
          NotificationManager.error('Warning message', 'Email nebol odoslaný. Vyskytla sa chyba.', 3000);
        })

        resetForm()
    } 



  }

  const validationSchema = Yup.object({
    price: Yup.string().optional().nullable(),
    firstname: Yup.string().required("Krstné meno dieťaťa je povinná hodnota, ktorú je potrebné vyplnit").min(1),
    lastname: Yup.string().required("Priezvisko dieťaťa je povinná hodnota, ktorú je potrebné vyplnit").min(1),
    email: Yup.string().email().required("Email je hodnota, ktorú je potrebné vyplnit").min(1),
    phone: Yup.string().required("Telefónne číslo je hodnota, ktorú je potrebné vyplnit").min(1),
    age: Yup.number().required("Vek je hodnota, ktorú je potrebné vyplnit").min(1),
    height: Yup.number().required("Výška dieťaťa je hodnota, ktorú je potrebné vyplnit").min(1),
    message: Yup.string().optional().nullable(),

    events: Yup.array()
      .min(1, "Vyberte minimálne jedenu z možnosti")
      .required("Vyberte minimálne jedenu z možnosti"),
  })

// const handleClick = e => {
//   console.log(e.target.value)
// //   console.log(selectedEvents)
// }

  return (

    <div>


      {/* Contact form element & details section with custom paddings */}
      <section className="hg_section pb-80" style={{ marginTop: "-50px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-9 col-lg-9 mb-sm-30">





              {/* Contact form element */}
              <div className="contactForm">
                <Formik initialValues={defaultValues}  validationSchema={validationSchema} onSubmit={async (values, { resetForm }) => { onSubmit(values, resetForm);  }} >
                  {({ values, setFieldValue }) => (
                  <Form className="contact_form row">



                    {/* <form action="php_helpers/_contact-process.php" method="post" className="contact_form row" encType="multipart/form-data"> */}
                    {/* Response wrapper */}
                    <div className="cf_response" />

                    <div className="col-sm-6 kl-fancy-form">
                      {/* <input type="text" name="name" id="cf_name" className="form-control" placeholder="Please enter your first name" defaultValue tabIndex={1} maxLength={35} required /> */}
                      <Field
                        name="firstname"
                        className="form-control"
                        placeholder={t('firstname') + ' dieťaťa'}
                        tabIndex={1}
                        maxLength={35}
                        type="text"
                      />
                      <div className="text-danger"><strong><ErrorMessage name="firstname" /></strong></div>

                      <label className="control-label">
                        {t('firstname').toUpperCase()}  dieťaťa
                      </label>
                    </div>

                    <div className="col-sm-6 kl-fancy-form">
                      {/* <input type="text" name="lastname" id="cf_lastname" className="form-control" placeholder="Please enter your first last name" defaultValue tabIndex={1} maxLength={35} required /> */}
                      <Field
                        name="lastname"
                        className="form-control"
                        placeholder={t('lastname') + ' dieťaťa'}
                        tabIndex={1}
                        maxLength={35}
                        required
                        type="text"
                      />
                      <div className="text-danger"><strong><ErrorMessage name="lastname" /></strong></div>
                      <label className="control-label">
                        {t('lastname').toUpperCase()} dieťaťa
                      </label>
                    </div>

                    <div className="col-sm-12 kl-fancy-form">
                      {/* <input type="text" name="email" id="cf_email" className="form-control h5-email" placeholder="Please enter your email address" defaultValue tabIndex={1} maxLength={35} required /> */}
                      <Field
                        name="email"
                        id="cf_email"
                        className="form-control h5-email"
                        placeholder={t('yourEmail')}
                        tabIndex={1}
                        maxLength={50}
                        required
                        type="text"
                      />
                      <div className="text-danger"><strong><ErrorMessage name="email" /></strong></div>

                      <label className="control-label">
                        {t('email').toUpperCase()}
                      </label>
                    </div>

                    <div className="col-sm-12 kl-fancy-form">
                      <Field
                        name="phone"
                        id="cf_phone"
                        className="form-control"
                        placeholder="Telefonny kontakt na rodiča"
                        tabIndex={1}
                        maxLength={50}
                        required
                        type="text"
                      />
                      <div className="text-danger"><strong><ErrorMessage name="subject" /></strong></div>

                      <label className="control-label">
                        Telefónny kontakt
                      </label>
                    </div>


                    <div className="col-sm-6 kl-fancy-form">
                      {/* <input type="text" name="subject" id="cf_subject" className="form-control" placeholder="Enter the subject message" defaultValue tabIndex={1} maxLength={35} required /> */}
                      <Field
                        name="age"
                        id="cf_age"
                        className="form-control"
                        placeholder="Číselná hodnota - vek dieťaťa"
                        tabIndex={1}
                        maxLength={2}
                        required
                        type="number"
                      />
                      <div className="text-danger"><strong><ErrorMessage name="age" /></strong></div>

                      <label className="control-label">
                        Vek dieťaťa:
                      </label>
                    </div>


                    <div className="col-sm-6 kl-fancy-form">
                      {/* <input type="text" name="subject" id="cf_subject" className="form-control" placeholder="Enter the subject message" defaultValue tabIndex={1} maxLength={35} required /> */}
                      <Field
                        name="height"
                        className="form-control"
                        placeholder="Výška dieťaťa v cm (číselná hodnota) pre zabezpečenie kimona"
                        tabIndex={1}
                        maxLength={2}
                        required
                        type="number"
                      />
                      <div className="text-danger"><strong><ErrorMessage name="height" /></strong></div>

                      <label className="control-label">
                        Výška dieťaťa v cm:
                      </label>
                    </div>




                    <div className="col-sm-12 kl-fancy-form">
                      <Field component="textarea" name="message" id="cf_message" className="form-control" cols={30} rows={10} placeholder="Doplňujúce informácie o vašom dieťati, intolerancie, alergie, resp. akékoľvek informácie, ktoré si myslíte že by sme mali vedieť." tabIndex={4} />
                      <div className="text-danger"><strong><ErrorMessage name="message" /></strong></div>
                      <label className="control-label">
                        {t('message').toUpperCase()}

                      </label>
                    </div>





                    <div className="col-sm-12 pb-20">


                      <div className="ib2-style2 ib2-text-color-light-theme ib2-custom" style={{ backgroundColor: '#ffffff' }}>
                        <div className="ib2-inner">
                          <h4>TERMÍNY</h4>
                          <div className="ib2-content">
                            {/* <h3 className="ib2-content--title">Kallyas template is an all in one product with lots of goodies and features.</h3> */}
                            <div className="ib2-content--text">
                              <ul>
                              {/* {summerCampsEvents.length && summerCampsEvents.map(event => 
                                <li key={event.id}>
                                <Field
                                  name="events"
                                  value={event.id}
                                  type="checkbox"
                                  // checked={values.check}
                                  // setFieldValue("c", event.target.checked);
                                  onChange={() => setFieldValue("events", !values.check)}                                  
                                  // checked={values.events ? true : false}
                                  // checked={field.value} 
                                  // onChange={handleChange}                
                                />
                                <label className="control-label+ pl-20">{event.name}</label>
                              </li>
                              )} */}

                              {/* <p>Among many reasons, what makes Kallyas so special is the large number of built-in features and integrations, along premium powerful plugins. You be the creative, while we're going to ensure a proper infrastructure for your website, all live in the frontend of your website.</p> */}
                              <li>
                                  <Field
                                    name="events"
                                    value="1"
                                    type="checkbox"
                                    // onChange={ (e) => handleClick(e)}

                                  />
                                  <label className="control-label+ pl-20">Bullyproof detský letný tábor - Prvý turnus 14. - 18. 7. 2025</label>
                                </li>
                                <li>
                                  <Field
                                    name="events"
                                    value="2"
                                    type="checkbox"
                                    // onChange={ (e) => handleClick(e)}

                                  />
                                  <label className="control-label+ pl-20">Bullyproof detský letný tábor - Druhý turnus 18. - 22. 8. 2025</label>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="text-danger"><strong><ErrorMessage name="events" /></strong></div>
                        </div>


                      </div>




                    </div>


                    <div className="col-sm-12">
                      <div className="ib2-style2 ib2-text-color-light-theme ib2-custom" style={{ backgroundColor: '#ffffff' }}>
                        <div className="ib2-inner">
                          <h4>GI (Kimono)</h4>
                          <div className="ib2-content">

                            <ul>
                              <li>
                                <Field
                                  name="has_own_gi"
                                  type="checkbox"
                                  onChange={handleOnChange}
                                  checked={isChecked}

                                />
                                <label className="control-label pl-20">Nepotrebujeme nové GI (kimino), pretože moje dieťa má vlastné biele GI ( -20 € z ceny tábora )</label>
                              </li>
                            </ul>

                          </div></div></div>
                    </div>



                    {/* <div className="col-sm-12 kl-fancy-form"> */}
                    <div className="col-sm-12 mb-30 pt-20 pb-20 mt-30 ml-20 border" style={{ backgroundColor: '#ffffff' }}>

                      <label className="control-label">
                        <span><strong>ZASLANÍM PRIHLÁŠKY UDEĽUJEM SÚHLAS, V SÚLADE S PLATNÝM ZÁKONOM O OCHRANE OSOBNÝCH ÚDAJOV A BERIEM NA VEDOMIE PRAVIDLÁ A PODMIENKY ÚČASTI V DETSKOM LETNOM TÁBORE</strong></span>
                        <ol className="list-group list-group-numbered">
                          <li> 1. <Link to="/gdpr">Zásady spracúvania osobných údajov (GDPR)</Link></li>
                          <li> 2. <Link to="/pravidla-podmienky">Pravidlá a podmienky v detskom letnom tábore</Link></li>
                        </ol>

                      </label>
                    </div>





                    <div className="col-sm-6">
                      <ReCAPTCHA
                        sitekey="6LdiYGAkAAAAAB896FlVk3v6yxe9A5-3YTgrFtXF"
                        ref={captchaRef}
                      />
                    </div>
                    <div className="col-sm-6 text-right">
                      {/* Contact form send button */}
                      <button className="btn btn-fullcolor" type="submit">
                        Prihlásiť dieťa
                      </button>
                    </div>
                    {/* </form> */}
                  </Form>
                  )}
                </Formik>

              </div>
              {/*/ Contact form element */}
            </div>
            {/*/ col-sm-12 col-md-9 col-lg-9 mb-sm-30 */}
            <div className="col-sm-12 col-md-3 col-lg-3">
              {/* Contact details */}
              <div className="text_box">
                {/* Title */}
                <h3 className="kl-iconbox__title">
                  Priháška
                </h3>
                <h4 style={{ fontSize: "90%" }}><strong>Denný tábor Brazílskeho Jiu-Jitsu 2025</strong></h4>
                <br />
                {/* Sub-title */}
                <p>
                  <i className="fa fa-at"></i> <a href="mailto:mailto://info@bjjlevice.sk">info@bjjlevice.sk</a><br />
                  <i className="fa fa-phone"></i> <span>0918 107 782</span>
                </p>
              </div>
              {/*/ Contact details */}


              <div className="text-center pt-20 card" style={{ borderColor: "#cd2122" }}>
                <div style={{ margin: "10px" }}>
                  <div className="pb-30">
                    {/* <img src="/images/15.png" alt="15% zlava" /> */}
                  </div>
                  <div className="fs-m reset-line-height">
                    Prihláste sa už dnes!
                    {/* <strong className="kl-iconbox__title">ZĽAVA 15%</strong> pre prvých 15 registrácii. */}
                  </div>

                  <div className="nlbox--hugediscount text-center-sm" />

                  <div className="fs-m reset-line-height mb-30">
                    súrodenecká zľava pre deti, alebo registrácia do 30. apríla so zľavou
                    {/* Máme posledné <br />4 voľné miesta. */}
                  </div>

                  <div className="nlbox--hugediscount text-center-sm" />

                  <div className="fs-m reset-line-height mb-30 pt-30 kl-iconbox__title">
                    <div style={{ weight: "999" }} className="pb-10">AKTUÁLNA CENA JE</div>
                    <div style={{ fontSize: "200%", weight: "999" }}><strong>{price} &euro;</strong></div>
                  </div>

                </div>
              </div>




            </div>
            {/*/ col-sm-12 col-md-3 col-lg-3 */}
          </div>
          {/*/ .row */}
        </div>
        {/*/ .container */}
      </section>
      {/*/ Contact form element & details section with custom paddings */}
    </div>

  )
}
export default PrihlaskaLetnyTabor