import React from 'react'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import {NotificationContainer} from 'react-notifications'
import HomePage from './pages/HomePage'
import Kontakt from './pages/Kontakt'
import Err404 from './pages/Err404'
// import Harmonogram from './pages/Harmonogram'
// import Prihlaska from './pages/Prihlaska'
// import Faq from './pages/Faq'
// import GracieCombatives from './pages/GracieCombatives'
// import GracieBullyproof from './pages/GracieBullyproof'
// import MasterCycle from './pages/MasterCycle'
// import PrivateLessons from './pages/PrivateLessons'
import i18next from 'i18next'
import { useSelector } from "react-redux"
import SummerCamp from './pages/SummerCamp'
import Gdpr from './pages/Gdpr'
import LetnyTaborPravidla from './pages/LetnyTaborPravidla'
import Kids from './pages/Programs/Kids'
// import Dospelaci from './pages/Dospelaci'
import Harmonogram from './pages/Harmonogram'
// import Platba from './pages/Platba'
import PrivateLessons from './pages/Programs/PrivateLessons'
import Eshop from './pages/Eshop'
import Faq from './pages/Faq'
import MasterCycle from './pages/Programs/MasterCycle'
import GracieCombatives from './pages/Programs/GracieCombatives'
import WomenEmpowered from './pages/Programs/WomenEmpowered'
// import OneOOneSeminar from './pages/101seminar'
import DvePercenta from './pages/DvePercenta'

function App() {
    const { language } = useSelector((state) => state)
    i18next.changeLanguage(language)

    return (
    <Router>
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/letny-tabor" component={SummerCamp} />
            <Route exact path="/pravidla-podmienky" component={LetnyTaborPravidla} />

            <Route exact path="/deti" component={Kids} />
            <Route exact path="/gracie-bullyproof" component={Kids} />
            
            <Route exact path="/gracie-combatives" component={GracieCombatives} />
            <Route exact path="/dospelaci" component={GracieCombatives} />

            <Route exact path="/gracie-master-cycle" component={MasterCycle} />
            
            <Route exact path="/women-empowered" component={WomenEmpowered} />
            
            <Route exact path="/individualne-treningy" component={PrivateLessons} />            
            <Route exact path="/private-lessons" component={PrivateLessons} />            
            
            <Route exact path="/harmonogram" component={Harmonogram} />
            <Route exact path="/gdpr" component={Gdpr} />
            <Route exact path="/kontakt" component={Kontakt} />
            <Route exact path="/eshop" component={Eshop} />
            <Route exact path="/top10otazok" component={Faq} />
            <Route exact path="/top10questions" component={Faq} />
            {/* <Route exact path="/101seminar" component={OneOOneSeminar} /> */}
            <Route exact path="/vase-2-percenta-pomozu" component={DvePercenta} />
            


            <Route path='*' component={Err404} />
            
        </Switch>

        <NotificationContainer/>
    </Router>
    )
}

export default App;

