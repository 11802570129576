import React from "react"
import { Link } from "react-router-dom"
// import { useDispatch, useSelector } from "react-redux"
// import { useDispatch } from "react-redux"
// import { changeLanguage } from "../modules/language"
import MainMenu from "./MainMenu"

const PageHeader = () => {
    // const { language } = useSelector((state) => state)

    // const dispatch = useDispatch();

    // const handleClick = (e) => {
    //   dispatch(changeLanguage(e))
  
    //   // console.log('Changing language to : ' + e)
    // }

    // React.useEffect(() => {
    //   //console.log('changed')
    //   // console.log (language)
    // }, [])
    
    
    return (
      
    <header id="header" className="site-header cta_button" data-header-style={1}>
      <div className="kl-header-bg" />
      <div className="site-header-wrapper">

        
        <div className="site-header-top-wrapper">
          <div className="siteheader-container container">
            <div className="site-header-row site-header-top d-flex justify-content-between">

              <div className="site-header-top-left d-flex">
                <ul className="topnav social-icons sc--clean align-self-center">
                  <li>
                    <a href="https://www.facebook.com/bjjlevice/" target="_blank" rel="noreferrer" title="Facebook">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/bjjlevice/" target="_blank" rel="noreferrer" title="Instagram">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://www.youtube.com/@graciejiujitsulevice" target="_blank" rel="noreferrer" title="Youtube">
                      <i className="fab fa-youtube" />
                    </a>
                  </li> */}
                </ul>
                <div className="clearfix visible-xxs">
                </div>
              </div>


              <div className="site-header-top-right d-flex">
                {/* Languages */}
                {/* 
                <div className="topnav topnav--lang align-self-center">
                  <div className="languages drop">
                    <a href="#" className="topnav-item">
                      <span className="fas fa-globe xs-icon" />
                      <span className="topnav-item--text">LANGUAGES</span>
                    </a>
                    <div className="pPanel">
                      <ul className="inner">
                        <li className="toplang-item">
                          <a href="#" onClick={(e) => handleClick('en')} >
                            <img src="/images/en.svg" alt="English" className="toplang-flag " /> English
                          </a>
                        </li>
                        <li className="toplang-item">
                          <a href="#" onClick={(e) => handleClick('sk')} >
                            <img src="/images/sk.svg" alt="Slovak" className="toplang-flag " /> Slovak
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> 
                */}
                {/*/ Languages */}

                {/* Login trigger */}
                <div className="topnav login--panel align-self-center">
                  {/* <a className="topnav-item popup-with-form" href="#login_panel">
                    <i className="login-icon fas fa-sign-in-alt visible-xs xs-icon" />
                    
                  </a>*/}

                  <Link to="/letny-tabor" className="topnav-item popup-with-form" href="#login_panel"><span className="topnav-item--text"  style={{color: "#fff"}}> 
                    <i className="login-icon fas fa-camp visible-xs xs-icon" /> Denný letný tábor Brazílskeho Jiu-Jitsu júl a august 2025</span>
                  </Link> 

                  {/* <Link to="/101seminar" className="topnav-item popup-with-form" href="#login_panel"><span className="topnav-item--text"  style={{color: "#fff"}}> 
                    <i className="login-icon fas fa-camp visible-xs xs-icon" /> 8. marec 2025 o 10:00 - Bezplatný seminár sebaobrany výlučne pre ženy</span>
                  </Link>  */}

                <div className="mainnav mainnav--cart d-flex align-self-center">
                  <div className="drop">
                    {/* <a href="/#" className="kl-cart-button" title="View your shopping cart">
                      <i className="fas fa-shopping-basket xs-icon" data-count={0} />
                      
                    </a> */}
                    
                    <div className="pPanel">
                      <div className="inner cart-container">
                        <div className="widget_shopping_cart_content">
                          <ul className="cart_list product_list_widget ">
                            <li>
                              <a href="/#" className="remove" title="Remove this item">×</a>
                              <a href="/#" className="product-title">
                                <img src="/images/_niches/university/university-cart.jpg" alt="Kallyas Book" title="Kallyas Book" />Kallyas Book
                              </a>
                              <span className="color-variations" />
                              <span className="quantity">1 × <span className="amount">$125</span></span></li>
                          </ul>
                          <p className="total">
                            <strong>Subtotal:</strong><span className="amount">$125</span>
                          </p>
                          <p className="buttons">
                            <a href="cart.html" className="button wc-forward">View Cart</a>
                            <a href="checkout.html" className="button checkout wc-forward">Checkout</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>

                </div>
                {/*/ Login trigger */}		


                              
              </div>



            </div>
            
            <div className="separator site-header-separator" />
          </div>
        </div> 
       

        <div className="site-header-main-wrapper d-flex">
          <div className="siteheader-container container align-self-center">
            <div className="site-header-row site-header-main d-flex flex-row justify-content-between">
                
                
            {/*  */}
              <div className="site-header-main-left d-flex justify-content-start align-items-center">
                <div className="logo-container hasInfoCard1 logosize-yes" style={{zIndex:20001}}>
                  <h1 className="site-logo logo" >
                    <Link to="/">
                      <img src="/images/gjj-logo.png" className="logo-img" alt="BJJ Levice" title="BJJ Levice" />
                    </Link>
                  </h1>

                  {/*                   
                  <div id="infocard" className="logo-infocard">
                    <div className="custom">
                      <div className="row">
                        <div className="col-sm-6 left-side d-flex">
                          <div className="align-self-center">
                            <div className="infocard-wrapper text-center">
                              <img src="/images/gjj-logo.png" className="mb-25" alt="Kallyas" title="Kallyas" />
                              <p>Kallyas is an gigantic ultra-premium, responsive template built for today websites with over <strong>350 elements</strong>.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 right-side">
                          <div className="custom contact-details">
                            <p>
                              BJJ Levice<br />
                              M.R.Stefanika 1, Levice <br />
                              <a href="mailto:hello@gjj.sk">hello@gjj.sk</a>
                            </p>
                            <a href="http://goo.gl/maps/1OhOu" className="map-link" target="_blank" rel="noreferrer" title="">
                              <span className="fas fa-map-marker-alt white-icon mr-10" />
                              <span>Open in Google Maps</span>
                            </a>
                          </div>
                          <div style={{height: 20}}>
                          </div>
                          <ul className="social-icons sc--clean">
                            <li><a href="/#" target="_self" className="fab fa-twitter" title="Twitter" /></li>
                            <li><a href="/#" target="_self" className="fab fa-facebook-f" title="Facebook" /></li>
                            <li><a href="/#" target="_self" className="fab fa-dribbble" title="Dribbble" /></li>
                            <li><a href="/#" target="_blank" rel="noreferrer" className="fab fa-google-plus-g" title="Google Plus" /></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                   */}

                </div>
                <div className="separator visible-xxs" />
              </div>
            {/*  */}


        
            <MainMenu />

        
              {/* Nakupny kosik*/}

              <div className="site-header-main-right d-flex justify-content-end align-items-center">
                {/* <div className="mainnav mainnav--cart d-flex align-self-center">
                  <div className="drop">
                    <a href="/#" className="kl-cart-button" title="View your shopping cart">
                      <i className="fas fa-shopping-basket xs-icon" data-count={0} />
                      
                    </a>
                    
                    <div className="pPanel">
                      <div className="inner cart-container">
                        <div className="widget_shopping_cart_content">
                          <ul className="cart_list product_list_widget ">
                            <li>
                              <a href="/#" className="remove" title="Remove this item">×</a>
                              <a href="/#" className="product-title">
                                <img src="/images/_niches/university/university-cart.jpg" alt="Kallyas Book" title="Kallyas Book" />Kallyas Book
                              </a>
                              <span className="color-variations" />
                              <span className="quantity">1 × <span className="amount">$125</span></span></li>
                          </ul>
                          <p className="total">
                            <strong>Subtotal:</strong><span className="amount">$125</span>
                          </p>
                          <p className="buttons">
                            <a href="cart.html" className="button wc-forward">View Cart</a>
                            <a href="checkout.html" className="button checkout wc-forward">Checkout</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div> */}
              </div> 
              


            </div>
          </div>
        </div>
      </div>
    </header>

    )

}

export default PageHeader

