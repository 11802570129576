import React from "react"
// import { Link } from "react-router-dom"
// import { useTranslation } from 'react-i18next'
import {Link} from "react-router-dom";
import ReactPlayer from 'react-player'
import Vid from "../BPSummerCampPromo.mov"

const LetnyTaborUputavka = () => {
  // const {t} = useTranslation('translation')

    return (

    <section className="hg_section bg-white">
      <div className="container">
        <div className="row">
          {/* <div className="col-sm-12 col-md-9 col-lg-9"> */}
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="kl-title-block">
              <h3 className="tbk__title kl-font-alt fs-l fw-bold tcolor">
              Denný tábor Brazílskeho jiu-jitsu 2025
              </h3>
              <div className="tab-pane fade show active tab-content">
                {/* <h4>
                  Customer Support
                </h4> */}
                <div className="col-8">
                  {/* <img src="/images/letnytabor2023.png" className="alignleft img-fluid" alt="title" /> */}
                  <ReactPlayer
                  className="alignleft img-fluid"
                    url={Vid}
                    playing={true}
                    controls={true}
                    loop={true}
                    muted={true}
                    playsinline={true}
                    // onReady={onLoadedData}
                  />
                </div>
                
                {/* <div > */}
                
                {/* <h3 className="fw-semibold fs-xl fs-xs-xl tcolor">Všetký miesta v detskom tábore BJJ sú zarezervované 🙂</h3>
                <p>Ďakujeme za vašu dôveru. Už sa nevieme dočkať augusta na kopec zábavy a skúsenosti s Bjj. </p>
                <hr />
                <p>Zároveň spúšťame rezervačný systém na detské treningy od septembra cez odkaz nižšie. Ak sa vám neušlo miesto v tábore neváhajte využiť možnosť trénovať s nami od sempebra.</p>
                <ul>
                  <li>🧸 3-5 rokov</li>
                  <li>🏆 5-8 rokov</li>
                  <li>🥋 8-12 rokov</li>
                </ul>
                <br />
                <p>Viac info:</p>                  

                <div>
                  <Link to="/deti" className="btn-element btn btn-fullcolor btn-md" title="Letny kemp mt-20 mb-20">
                  <span>Program pre deti</span>
                  </Link>
                </div> */}



                
              
              {/* <h4 className="tbk__subtitle fs-s fw-thin">
              Je sa na čo tešiť! Denný tábor brazílskeho jiu jitsu sme tu ešte nemali! Že nevieš, čo to je? Nevadí, my Ťa to naučíme.  Jiu jitsu znamená v preklade jemné umenie a osvojiť si ho môže naozaj každý, vrátane detí a juniorov. Tento tábor Ti dá naozaj veľa, predovšetkým kopec zábavy, nových priateľov a skvelé zážitky,  avšak okrem klasických táborových radostí si odnesieš z tohto týždňa aj skvelú skúsenosť ako sa zachovať v rôznych nepríjemných situáciách, ako sa im vyhýbať, ako sa brániť šikane. Zvýšiš si sebavedomie a fyzickú zdatnosť. A to všetko  pod dohľadom certifikovaných inštruktorov. Navyše môžeš po tábore pokračovať v tréningu brazílskeho jiu jitsu celoročne. Zaži s nami niečo výnimočné, čo nikde inde nenájdeš a uchmatni si svoje miesto v tábore.
              </h4> */}
            {/* </div> */}

            <div className="col-sm-12 col-md-3 co-lg-3 d-flex align-self-center justify-content-center">              
              <div className="text-center pt-20 card" style={{borderColor: "#cd2122"}}>
                  <Link to="/letny-tabor">
                  <div style={{margin: "10px"}}>
                    {/* <div className="pb-30">
                      <img src="/images/15.png" alt="15% zlava" />
                    </div> */}
                    <div className="fs-m reset-line-height">
                      Prihláste svoje dieťa už dnes! <br />
                      {/* <strong className="kl-iconbox__title">ZĽAVA 10%</strong> <br /><br /> */}
                    </div>
                    
                    
                    <div className="nlbox--hugediscount text-center-sm" />

                    <div className="fs-m reset-line-height mb-30 pt-30 kl-iconbox__title">
                    <div style={{weight: "999"}} className="pb-10">AKTUÁLNA ZĽAVNENÁ CENA</div>
                    <div style={{fontSize: "200%", weight: "999"}}><strong>169 &euro;</strong></div>
                    </div>

                    <div className="nlbox--hugediscount text-center-sm" />
                    
                    <div className="fs-m reset-line-height mb-30">
                      <small>zľava platí pri registrácii<br />do 30. apríla</small>
                    </div>

                  </div>
                  </Link>

                  
                  <Link to="/letny-tabor" className="btn-element btn btn-fullcolor btn-md btn-fullwidth" title="Letny kemp mt-20 mb-20">
                  <span>INFORMÁCIE O TÁBORE</span>
                  </Link> 



                  </div>
                </div>

            </div>
            

            </div>
        </div>
      </div>
      </div>
    </section>


    )

}

export default LetnyTaborUputavka
