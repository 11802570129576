// import {Link} from "react-router-dom";
import ReactPlayer from 'react-player'
import Vid from "../BPSummerCampPromo.mov"


function SummerCampComponent(props) {

    return (

        <div>

            <div id="page_header" className="page-subheader site-subheader-cst ">
                <div className="bgback" />
                <div className="th-sparkles" />
                <div className="kl-bg-source">
                    <div className="kl-bg-source__bgimage" style={{ backgroundImage: 'url("images/general-bg-4.jpg")', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPosition: '50% 50%', backgroundSize: 'cover' }} />
                    <div className="kl-bg-source__overlay" style={{ backgroundColor: 'rgba(53, 53, 53, 0.1)' }} />
                </div>
                <div className="ph-content-wrap d-flex"><div className="container align-self-center">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="clearfix" /></div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="subheader-titles" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: 15 }}>
                                    <h2 className="subheader-maintitle">Denný tábor Brazílskeho Jiu-Jitsu  Júl a August 2025</h2>
                                    <h4 className="subheader-subtitle">od 6 do 13 rokov</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <section className="hg_section" style={{backgroundColor: "#f0f0f0"}}>
                <div className="container">
                    <div className="row">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">Denný letný tábor Brazílskeho Jiu-Jitsu</h3>
                        <div className="col-sm-7 col-md-7">
                            <div className="screenshot-box">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        {/* <img src="images/bjj-tabor.png" alt="Denný tábor Brazílskeho jiu-jitsu" /> */}
                                        <ReactPlayer
                                            url={Vid}
                                            playing={true}
                                            controls={true}
                                            loop={true}
                                            muted={true}
                                            playsinline={true}
                                            // onReady={onLoadedData}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-5 col-md-5">
                            <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-titl">
                                
                                <p>Náš 5-dňový tábor sa bude konať od pondelka do piatku od 8:00 do 15:00 a je určený pre deti od 6 do 13 rokov.</p>
                                <p>Každé dieťa sa v určitom okamihu svojho života stretne so šikanov a v tejto modejnej dobe technológií je situácia ešte horšia, pretože šikanovanie nemusí byť iba fyzické. </p>
                                <p>Naším cieľom je dať Vášmu dieťaťu zručnosť verbálnej a fyzickej sebaobrany, ako aj sebavedomie stanoviť hranice, aby v prípade, že naň niekto zaútočí, mohlo ukončiť obťažovanie skôr, než sa vyeskaluje a bude neskoro.</p>
                            </div>
                            

                        </div>


                    </div>
                </div>
            </section>


            {/* <section className="hg_section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                        <img src="images/BJJLevice-CTC-Bullyproof-Summer-Camp-Flyer-2024.png" alt="Denný tábor Brazílskeho jiu-jitsu" />

                        </div>
                    </div>
                </div>
            </section> */}


            <section id="services" className="hg_section">
                <div className="container">

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="th-spacer clearfix" style={{ height: 25 }}>
                            </div>

                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/muscles-muscle.svg" style={{width: "100px"}} alt="TURBO CHARGING" title="VOICE CONTROL" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                                Neotrasiteľná dôvera s Brazílskym Jiu-Jitsu
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Tým, že svojmu dieťaťu poskytnete nenásilné techniky Brazílskeho Jiu-Jitsu na neutralizáciu fyzického útoku, získa sebavedomie postaviť sa proti šikanovaniu skôr, ako sa obťažovanie vymkne kontrole.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/sound-2-svgrepo-com.svg" style={{width: "100px"}} alt="TURBO CHARGING" title="VOICE CONTROL" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                                Nácvik verbálnej sebaobrany
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Verbálne obťažovanie je bežnejšie a častokrát škodlivejšie ako fyzické obťažovanie. Aby bolo vaše dieťa pripravené identifikovať a prekonať tieto útoky, každý deň v tábore vykonávame sériu špecifických cvičení na hranie rolí.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/fitness-outline-svgrepo-com.svg" style={{width: "100px"}} alt="TURBO CHARGING" title="VOICE CONTROL" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            Zábava a fitness pre všetky vekové kategórie
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Deti milujú zábavu a pohyb. Každé ráno začíname „rozcvičkou zvieratiek“ a počas dňa hráme niekoľko ďalších hier, ktorých cieľom je poskytnúť vášmu dieťaťu cvičenie, ktoré potrebuje, a zábavu, ktorú chce!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/no-drinking-islam-svgrepo-com.svg" style={{width: "100px"}} alt="TURBO CHARGING" title="VOICE CONTROL" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            Vývoj postavy a obrana proti drogám
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Spojenie, ktoré rozvíjame s účastníkmi tábora je silné a toto spojenie využívame na vštepovanie životných lekcií, ktoré každé dieťa potrebuje. Každý deň vyberáme inú tému, od disciplíny a zdravého života až po protidrogovú obranu a prevenciu únosov, aby bolo vaše dieťa pripravené na život mimo ihriska.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/bluepay-credit-card-svgrepo-com.svg" style={{width: "100px"}} alt="TURBO CHARGING" title="VOICE CONTROL" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            Zaregistrujte sa ešte dnes!
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Ak sa chcete dozvedieť viac o letnom tábore kontaktujte nás a my Vám radi odpovieme. <br />
                                            </p>
                                            <p className="kl-iconbox__desc">
                                            <strong>Alebo sa príďte presvedčiť sami na tréningy pre deti <br />
                                            v pondelok, alebo stredu o 16:00 (tréning detí od 5 do 8 rokov) alebo o 17:00 (tréning detí od 8 do 13 rokov) <br /> 
                                            v našom gyme na Holubyho 5, Levice.</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>


                </div>
            </section>




        </div>
    )
}
export default SummerCampComponent